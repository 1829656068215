export const REGISTER_RESPONSE = "REGISTER_RESPONSE"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD ="FORGOT_PASSWORD";
export const VERIFY_EMAIL ="VERIFY_EMAIL";
export const VERIFY_OTP = "VERIFY_OTP";
export const VALIDATE_OTP = "VALIDATE_OTP"
export const RESEND_OTP = "RESEND_OTP"
export const REMOVE_EMAIL = "REMOVE_EMAIL";
export const REMOVE_FORGOT_PASSWORD ="REMOVE_FORGOT_PASSWORD";
export const REMOVE_OTP = "REMOVE_OTP"
export const RESET_PASSWORD = "RESET-PASSWORD"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const GET_NAVITEMS = "GET_NAVITEMS"
export const CONTACT_US = "CONTACT_US"
 

export const SHOW_TOAST = "SHOW_TOAST"


export const BOOK_SLOTS = "BOOK_SLOTS"